import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#f2ebd5',
    [Prop.HEADER]: '#be2026',
    [Prop.CONTENT]: '#411312',
    [Prop.LINK_TXT]: '#be2026',
    [Prop.LINK_TXT_HOVER]: '#d85b27',
    [Prop.BTN_BG]: '#f2ae2e',
    [Prop.BTN_TXT]: '#f2ebd5',
    [Prop.BTN_BG_HOVER]: '#be2026',
    [Prop.BTN_TXT_HOVER]: '#f2ebd5',
    [Prop.BTN_BG_ACTIVE]: '#d85b27',
    [Prop.BTN_TXT_ACTIVE]: '#f2ebd5',
    [Prop.BTN_BG_INACTIVE]: '#f2ebd5',
    [Prop.BTN_TXT_INACTIVE]: '#f2ae2e',
    [Prop.BORDER]: '#411312',
    [Prop.ELEMENT]: '#f2ae2e',
    [Prop.ELEMENT_TXT]: '#f2ebd5',
    [Prop.ICON]: '#be2026',
    [Prop.ICON_HOVER]: '#d85b27',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#f2ae2e',
    [Prop.HEADER]: '#411312',
    [Prop.CONTENT]: '#f2ebd5',
    [Prop.LINK_TXT]: '#be2026',
    [Prop.LINK_TXT_HOVER]: '#d85b27',
    [Prop.BTN_BG]: '#411312',
    [Prop.BTN_TXT]: '#f2ebd5',
    [Prop.BTN_BG_HOVER]: '#be2026',
    [Prop.BTN_TXT_HOVER]: '#f2ebd5',
    [Prop.BTN_BG_ACTIVE]: '#be2026',
    [Prop.BTN_TXT_ACTIVE]: '#f2ae2e',
    [Prop.BTN_BG_INACTIVE]: '#f2ebd5',
    [Prop.BTN_TXT_INACTIVE]: '#f2ae2e',
    [Prop.BORDER]: '#be2026',
    [Prop.ELEMENT]: '#d85b27',
    [Prop.ELEMENT_TXT]: '#f2ebd5',
    [Prop.ICON]: '#be2026',
    [Prop.ICON_HOVER]: '#411312',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#d85b27',
    [Prop.HEADER]: '#411312',
    [Prop.CONTENT]: '#f2ebd5',
    [Prop.LINK_TXT]: '#f2ae2e',
    [Prop.LINK_TXT_HOVER]: '#be2026',
    [Prop.BTN_BG]: '#411312',
    [Prop.BTN_TXT]: '#f2ebd5',
    [Prop.BTN_BG_HOVER]: '#f2ae2e',
    [Prop.BTN_TXT_HOVER]: '#f2ebd5',
    [Prop.BTN_BG_ACTIVE]: '#f2ae2e',
    [Prop.BTN_TXT_ACTIVE]: '#be2026',
    [Prop.BTN_BG_INACTIVE]: '#f2ebd5',
    [Prop.BTN_TXT_INACTIVE]: '#f2ae2e',
    [Prop.BORDER]: '#f2ebd5',
    [Prop.ELEMENT]: '#f2ae2e',
    [Prop.ELEMENT_TXT]: '#f2ebd5',
    [Prop.ICON]: '#be2026',
    [Prop.ICON_HOVER]: '#f2ebd5',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#be2026',
    [Prop.HEADER]: '#411312',
    [Prop.CONTENT]: '#f2ebd5',
    [Prop.LINK_TXT]: '#f2ae2e',
    [Prop.LINK_TXT_HOVER]: '#d85b27',
    [Prop.BTN_BG]: '#411312',
    [Prop.BTN_TXT]: '#f2ebd5',
    [Prop.BTN_BG_HOVER]: '#f2ae2e',
    [Prop.BTN_TXT_HOVER]: '#f2ebd5',
    [Prop.BTN_BG_ACTIVE]: '#f2ae2e',
    [Prop.BTN_TXT_ACTIVE]: '#be2026',
    [Prop.BTN_BG_INACTIVE]: '#f2ebd5',
    [Prop.BTN_TXT_INACTIVE]: '#f2ae2e',
    [Prop.BORDER]: '#f2ae2e',
    [Prop.ELEMENT]: '#411312',
    [Prop.ELEMENT_TXT]: '#f2ebd5',
    [Prop.ICON]: '#411312',
    [Prop.ICON_HOVER]: '#f2ae2e',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#411312',
    [Prop.HEADER]: '#f2ae2e',
    [Prop.CONTENT]: '#f2ebd5',
    [Prop.LINK_TXT]: '#d85b27',
    [Prop.LINK_TXT_HOVER]: '#f2ae2e',
    [Prop.BTN_BG]: '#d85b27',
    [Prop.BTN_TXT]: '#f2ebd5',
    [Prop.BTN_BG_HOVER]: '#f2ae2e',
    [Prop.BTN_TXT_HOVER]: '#f2ebd5',
    [Prop.BTN_BG_ACTIVE]: '#be2026',
    [Prop.BTN_TXT_ACTIVE]: '#f2ae2e',
    [Prop.BTN_BG_INACTIVE]: '#f2ebd5',
    [Prop.BTN_TXT_INACTIVE]: '#f2ae2e',
    [Prop.BORDER]: '#f2ebd5',
    [Prop.ELEMENT]: '#f2ae2e',
    [Prop.ELEMENT_TXT]: '#f2ebd5',
    [Prop.ICON]: '#f2ebd5',
    [Prop.ICON_HOVER]: '#f2ae2e',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#f2ae2e',
    [Prop.NAV_BG]: '#f2ae2e',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#411312',
    [Prop.NAV_ITEM_TXT_SOLID]: '#411312',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#411312',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#f2ae2e',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#be2026',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
};

import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG30: '18px',
  REG40: '20px',
  REG50: '22px',
  MED10: '24px',
  MED20: '26px',
  MED30: '28px',
  MED40: '30px',
  MED50: '32px',
  LRG10: '34px',
  LRG12: '40px',
  LRG20: '45px',
  LRG30: '50px',
  LRG40: '55px',
  LRG50: '60px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "VosterTypefaceRegular"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "VosterTypefaceRegular"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "ArchivoNarrowBold"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "ArchivoNarrowRegular"',
};

export const fontTheme = {
  fonts,
  FontSize,
};

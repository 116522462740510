import { Components, ColorProfiles } from '@powdr/constants';

// Color Profiles
const {
  BASE, PRIMARY, SECONDARY, TERTIARY, QUATERNARY,
} = ColorProfiles;

export const config = {
  [Components.HEADER]: PRIMARY,
  [Components.MAIN_NAV]: PRIMARY,
  [Components.MEGA_NAV]: QUATERNARY,
  [Components.MOBILE_NAV]: PRIMARY,
  [Components.ECOMMERCE_BANNER]: TERTIARY,
  [Components.WEATHER_FEED]: SECONDARY,
  [Components.PROMOTIONS_FEED]: QUATERNARY,
  [Components.PROMOTIONS]: BASE,
  [Components.ALERT_BAR]: QUATERNARY,
  [Components.STICKY_NAV]: TERTIARY,
  [Components.TERTIARY_NAV]: PRIMARY,
  [Components.LEVELS_MODAL]: BASE,
  [Components.FOOTER]: QUATERNARY,
  [Components.PARTNERS]: QUATERNARY,
  [Components.COPYRIGHT]: QUATERNARY,
  [Components.MODAL]: PRIMARY,
  layout: {
    headerHeight: 120,
    headerHeightMobile: 80,
    headerLogoContainerWidth: 220,
    headerFeedContainer: 220,
  },
};
